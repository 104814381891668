import request from "../base/request";

export const authApi = async (data) =>
  request({
    url: "/core/auth/sign-in",
    method: "POST",
    data,
    msgSuccess: "successLogin",
  });

export const signUpApi = async (data) =>
  request({
    url: "/core/auth/sign-up",
    method: "POST",
    data,
    // msgSuccess: "successSignUp",
  });

export const getMeApi = async () =>
  request({
    url: "/core/user/me",
    method: "GET",
    tokenClient: true,
  });

export const depositApi = async (data) =>
  request({
    url: "/v1/pin-card/use",
    method: "POST",
    data,
    msgSuccess: "successDeposit",
  });

export const walletMeApi = async () =>
  request({
    url: "/v1/wallet/me",
    method: "GET",
    tokenClient: true,
  });

export const treeMeApi = async () =>
  request({
    url: "/v1/referral-tree/me",
    method: "GET",
    tokenClient: true,
  });
