module.exports = {
  locales: ["vi", "en"],
  defaultLocale: "vi",
  localeDetection: false,
  pages: {
    "*": [
      "header",
      "home",
      "login",
      "register",
      "errors",
      "success",
      "deposit",
      "rule",
      "banner",
      "product",
      "about",
      "card",
      "support",
      "news",
      "footer",
      "sidebar",
      "profile",
      "changePass",
      "newsPage",
      "forgotPass",
      "gameHistory",
      "contact",
      "gift",
      "courses",
      "cardProduct",
      "board",
      "access-avatar",
      "box",
      "affiliate",
      "about-us",
      "transactionHistory",
      "feedback",
      "course-home",
      "help",
      "address",
      "ownedCourses",
      "consumHistory",
    ],
  },
};
