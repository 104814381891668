import React, { useState } from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import { Form, Input, Button } from "antd";
import Link from "next/link";

import { subscribingApi } from "@libs/apis/news";

import { rexgexEmail } from "@utils/regex";
import { FANPAGE_URL } from "configs/constant";

import { COMMUNITY, COMPANY, GAME, SOCIAL } from "./mockup";

import logo from "@public/images/logo.svg";

function Footer() {
  const { t } = useTranslation("footer");
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const backToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const onFinishForm = async (values) => {
    setLoading(true);

    subscribingApi({ email: values?.newsEmail })
      .then(() => {
        setLoading(false);
        form.resetFields();
      })
      .catch((err) => {
        console.log("== err", err);
        setLoading(false);
      });
  };

  return (
    <div className="footer">
      <div className="footer__logo">
        <div className="image" onClick={() => backToTop()}>
          <Image src={logo} alt="logo" />
        </div>
        <div className="social">
          {SOCIAL?.map((i, index) => (
            <div key={index}>
              <Link href={i?.link}>
                <a target="_blank">
                  <Image src={i?.image || ""} alt="icon" />
                </a>
              </Link>
            </div>
          ))}
        </div>
        <p dangerouslySetInnerHTML={{ __html: t("copyRight") }} />
      </div>
      <div className="footer__content">
        <div className="footer__content__col">
          <div className="footer__content__col--item">
            <Link href="/about-us">
              <a target="_blank">
                <h4>{t("us")}</h4>
              </a>
            </Link>
            {COMPANY?.map((i, index) => (
              <ul key={index}>
                <li>
                  {i?.link && (
                    <Link href={i?.link || ""}>
                      <a target="_blank">{i?.title || ""}</a>
                    </Link>
                  )}
                </li>
              </ul>
            ))}
          </div>
          <div className="footer__content__col--item">
            <Link href={FANPAGE_URL}>
              <a target="_blank">
                <h4>{t("community")}</h4>
              </a>
            </Link>
            {COMMUNITY?.map((i, index) => (
              <ul key={index}>
                <li>
                  <Link href={i?.link || ""}>
                    <a target="_blank">{i?.title || ""}</a>
                  </Link>
                </li>
              </ul>
            ))}
          </div>
          <div className="footer__content__col--item">
            <h4 className="footer__content__col--item--no-hover">
              {t("game")}
            </h4>
            {GAME?.map((i, index) => (
              <ul key={index}>
                <li>
                  <Link href={i?.link || ""}>
                    <a target="_blank">{i?.title || ""}</a>
                  </Link>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <div className="footer__contact">
        <Form
          name="subscribingForm"
          autoComplete="off"
          onFinish={onFinishForm}
          form={form}
        >
          <label>{t("note")}</label>
          <Form.Item
            name="newsEmail"
            rules={[
              {
                required: true,
                message: t("required"),
              },
              {
                pattern: new RegExp(rexgexEmail),
                message: t("ruleEmail"),
              },
            ]}
          >
            <div className="footer__contact__wrap">
              <Input placeholder={t("planceholder")} />
              <Button type="submit" htmlType="submit" loading={loading}>
                {t("signUp")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Footer;
