import { RESOURCE_URL, TRAINING_URL, SHARING_URL } from "configs/constant";

export const dropdown = [
  // {
  //   id: 1,
  //   name: 'deposit',
  //   hasArrow: true,
  //   hasLink: false,
  //   isSignOut: false,
  // },
  {
    id: 2,
    name: "referral",
    hasArrow: false,
    hasLink: true,
    isSignOut: false,
  },
  {
    id: 3,
    name: "userInfo",
    hasArrow: false,
    hasLink: false,
    isSignOut: false,
    link: "/account-management",
  },
  {
    id: 4,
    name: "affiliateManagement",
    hasArrow: false,
    hasLink: false,
    isSignOut: false,
    link: "/affiliate-management",
  },
  {
    id: 5,
    name: "signOut",
    hasArrow: false,
    hasLink: false,
    isSignOut: true,
  },
];

export const menus = [
  // {
  //   id: 1,
  //   name: "homepage",
  //   link: "/",
  // },
  {
    id: 2,
    name: "aboutUs",
    link: "/about-us",
  },
  {
    id: 3,
    name: "product",
    link: "/product",
    isDropDown: true,
  },
  {
    id: 4,
    name: "news",
    link: "/news",
  },
  {
    id: 5,
    name: "resource",
    link: RESOURCE_URL,
    newTab: true,
  },

  {
    id: 6,
    name: "experience",
    link: "/experience",
  },
  {
    id: 7,
    name: "training",
    link: TRAINING_URL,
    newTab: true,
  },
  {
    id: 8,
    name: "share",
    link: SHARING_URL,
    newTab: true,
  },
  {
    id: 9,
    name: "help",
    link: "/help",
  },
];

export const menuProducts = [
  {
    id: 1,
    name: "voucher",
    link: "/product/voucher-membership",
  },
  {
    id: 2,
    name: "courses",
    link: "/product/courses",
  },
  {
    id: 3,
    name: "board",
    link: "/product/board",
  },
];
