import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getListGameApi } from 'libs/apis/room';
import { getListSchoolApi } from '@libs/apis/school';

export const getListGameAction = createAsyncThunk(
  'user/listGame',
  async (params) => {
    const res = await getListGameApi(params);
    return res;
  },
);

export const getListSchoolAction = createAsyncThunk(
  'user/listSchool',
  async () => {
    const res = await getListSchoolApi();
    return res;
  },
);

const userSlice = createSlice({
  name: 'userReducer',
  initialState: {
    listGame: [],
    listSchool: [],
    showLogin: false,
    showRegister: false,
    showDeposit: false,
  },
  reducers: {
    handleUpdateShowLogin: (state, action) => {
      state.showLogin = action?.payload;
    },
    handleUpdateShowRegister: (state, action) => {
      state.showRegister = action?.payload;
    },
    handleUpdateShowDeposit: (state, action) => {
      state.showDeposit = action?.payload;
    },
  },
  extraReducers: {
    [getListGameAction.fulfilled]: (state, action) => {
      state.listGame = action?.payload?.data?.data?.docs?.reverse();
    },
    [getListSchoolAction.fulfilled]: (state, action) => {
      state.listSchool = action?.payload?.data?.data?.docs;
    },
  },
});

export const {
  handleUpdateShowLogin,
  handleUpdateShowRegister,
  handleUpdateShowDeposit,
} = userSlice.actions;

const { reducer: userReducer } = userSlice;
export default userReducer;
