import React from "react";
import { Col, Form, Row } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

import { changePassForgotApi } from "@libs/apis/user";

import Button from "@components/core/commons/Button";
import Input from "@components/core/commons/Input";

import iconPass from "@public/icons/icon-pass.svg";

const ForgotChangePass = ({ setStep, email, otpConfirm }) => {
  const { t } = useTranslation("forgotPass");
  const [form] = Form.useForm();

  const onFinishForm = async (data) => {
    const res = await changePassForgotApi({
      password: data?.password,
      email: email,
      otpCode: otpConfirm,
    });

    if (res?.status === 201) {
      setStep(4);
    }
  };

  return (
    <div className="step-3">
      <Form
        name="forgotPassStep3"
        autoComplete="off"
        onFinish={onFinishForm}
        form={form}
      >
        <div className="step-3__title">{t("title")}</div>

        <Row justify="center">
          <Col>
            <p className="step-3__label">{t("step3.label")}</p>
          </Col>
        </Row>

        <div className="step-3__layout">
          <Form.Item
            name="password"
            className="step-3__oldpass"
            rules={[
              {
                required: true,
                types: "pass",
                message: t("step3.requiredCofirm"),
              },
              { min: 6, message: t("step3.ruleMinPassword") },
            ]}
          >
            <Input
              type="text"
              icon={iconPass}
              isPassword
              placeholder={t("step3.plachoderPass")}
            />
          </Form.Item>

          <Form.Item
            name="otpCode"
            className="step-3__newpass"
            rules={[
              {
                required: true,
                types: "pass",
                message: t("step3.requiredCofirm"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error(t("step3.errorPass")));
                },
              }),
              { min: 6, message: t("step3.ruleMinPassword") },
            ]}
          >
            <Input
              type="text"
              icon={iconPass}
              isPassword
              placeholder={t("step3.plachoderCofirmPass")}
            />
          </Form.Item>
          <div className="step-3__layout__button">
            <Form.Item>
              <Button type="submit">{t("step3.btn")}</Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

ForgotChangePass.propTypes = {
  setStep: PropTypes.func,
  email: PropTypes.string,
  otpConfirm: PropTypes.string,
};
export default ForgotChangePass;
