import iconFb from "@public/images/icon-fb.svg";
import iconYtb from "@public/images/icon-ytb.svg";
// TO-DO
// import iconTw from "@public/images/icon-tw.svg";
// import iconInsta from "@public/images/icon-instar.svg";
// import iconIn from "@public/images/icon-in.svg";

import {
  RESOURCE_URL,
  VIDEO_URL,
  TRAINING_URL,
  LINK_POLICY,
  LINK_DIAMOND,
  LINK_BERICH,
  LINK_UNICORN,
  LINK_FAQ,
} from "configs/constant";

const COMPANY = [
  {
    key: 1,
    title: "FAQ",
    link: LINK_FAQ,
  },
  {
    key: 2,
    title: "Chính sách",
    link: LINK_POLICY,
  },
  {
    key: 3,
    title: "Đào tạo",
    link: TRAINING_URL,
  },
];

const COMMUNITY = [
  {
    key: 1,
    title: "CLB Be Rich",
    link: "/",
  },
  {
    key: 2,
    title: "Tài nguyên",
    link: RESOURCE_URL,
  },
  {
    key: 3,
    title: "Video",
    link: VIDEO_URL,
  },
];

const GAME = [
  {
    key: 3,
    title: "Cờ Diamond (Thân)",
    link: LINK_DIAMOND,
  },
  {
    key: 1,
    title: "Cờ Be Rich (Tâm)",
    link: LINK_BERICH,
  },
  {
    key: 2,
    title: "Cờ Unicorn (Trí)",
    link: LINK_UNICORN,
  },
];

const SOCIAL = [
  {
    key: 1,
    link: "https://www.facebook.com/clbberich",
    image: iconFb,
  },
  // {
  //   key: 2,
  //   link: "",
  //   image: iconTw,
  // },
  // {
  //   key: 3,
  //   link: "",
  //   image: iconInsta,
  // },
  {
    key: 4,
    link: "https://www.youtube.com/@clbberich",
    image: iconYtb,
  },
  // {
  //   key: 5,
  //   link: "",
  //   image: iconIn,
  // },
];
export { COMMUNITY, COMPANY, GAME, SOCIAL };
