import request from "../base/request";

export const getListGameApi = async (params) =>
  request({
    url: "/v1/game-list",
    method: "GET",
    params,
  });

export const checkJoinRoomApi = async (params) =>
  request({
    url: "/v1/check-gateway/room",
    method: "GET",
    params,
    tokenClient: true,
  });
