import { Modal as AntModal } from "antd";
import PropTypes from "prop-types";

Modal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  closable: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  visible: false,
  onCancel: () => {},
  closable: false,
  className: "",
};

export default function Modal({
  visible,
  onCancel,
  closable = false,
  className,
  children,
  width,
  maskClosable = true,
}) {
  return (
    <AntModal
      className={className}
      centered
      footer={null}
      open={visible}
      onCancel={onCancel}
      closable={closable}
      width={width}
      maskClosable={maskClosable}
    >
      {children}
    </AntModal>
  );
}

Modal.defaultProps = defaultProps;
