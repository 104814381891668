import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

import ToastMessage from "@components/core/commons/Toast";

import webStorage from "@utils/webStorage";

import { API_SERVER, API_BEUNICORN } from "../../configs/constant";

const SESSION_EXPIRED_STATUS_CODE = 401;

const request = async ({
  tokenClient,
  msgSuccess = "",
  isBeUnicornAPI = false,
  ...options
}) => {
  const baseApiConfig = {
    baseURL: !isBeUnicornAPI ? API_SERVER : API_BEUNICORN,
    headers: {
      "content-type": "application/json",
    },
    timeout: 60000, // 60s
    paramsSerializer: (params) => queryString.stringify(params),
  };

  const baseApiClient = axios.create(baseApiConfig);

  // /Clientside
  if (tokenClient) {
    const token = webStorage.getToken();
    baseApiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  const onSuccess = (response) => {
    if (msgSuccess) {
      toast.success(
        <ToastMessage message={msgSuccess} translation="success" />,
      );
    }

    return response;
  };

  const onError = (error) => {
    const messageList = [];

    if (error?.response?.status === SESSION_EXPIRED_STATUS_CODE) {
      webStorage.removeAll();
    }

    if (
      error?.response?.status !== SESSION_EXPIRED_STATUS_CODE &&
      error?.response?.data?.errors
    ) {
      const errData = error.response.data;
      if (errData.errors) {
        errData?.errors?.map((v) =>
          messageList.push(v.code?.replace(":", "_")),
        );
      }

      toast.error(<ToastMessage message={messageList} translation="errors" />);
    }

    return Promise.reject(error.response);
  };

  return baseApiClient(options).then(onSuccess).catch(onError);
};

export default request;
