import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { authApi, signUpApi, getMeApi, walletMeApi } from "libs/apis/auth";

import webStorage from "@utils/webStorage";

import { EXPIRED_DAYS } from "configs/enums";
import { USER_INFO, USER_WALLET } from "configs/constant";

export const loginAction = createAsyncThunk("auth/login", async (data) => {
  const res = await authApi(data);
  return res;
});

export const signUpAction = createAsyncThunk("auth/signUp", async (data) => {
  const res = await signUpApi(data);
  return res;
});

export const getMeAction = createAsyncThunk("auth/getMe", async () => {
  const res = await getMeApi();
  return res;
});

export const walletMeAction = createAsyncThunk("auth/walletMe", async () => {
  const res = await walletMeApi();
  return res;
});

const authSlice = createSlice({
  name: "authReducer",
  initialState: {
    isAuth: false,
    rememberLogin: false,
    user: webStorage.get(USER_INFO) || {},
    wallet: webStorage.get(USER_WALLET) || {},
  },
  reducers: {
    handleUpdateRememberLogin: (state, action) => {
      state.rememberLogin = action?.payload;
    },
    handleUpdateIsAuth: (state, action) => {
      state.isAuth = action?.payload;
    },
    handleSignOut: (state, action) => {
      webStorage.removeAll();
      state.isAuth = false;
      state.user = {};
    },
  },
  extraReducers: {
    [loginAction.fulfilled]: (state, action) => {
      const token = action?.payload?.data?.data?.accessToken;

      state.isAuth = true;

      if (state.rememberLogin) {
        webStorage.setToken(token, { expires: EXPIRED_DAYS });
      } else {
        webStorage.setToken(token);
      }
    },

    [signUpAction.fulfilled]: (state, action) => {},

    [getMeAction.rejected]: (state, action) => {
      webStorage.removeAll();
      state.isAuth = false;
      state.user = {};
    },

    [getMeAction.fulfilled]: (state, action) => {
      const user = action?.payload?.data?.data?.user;
      webStorage.set(USER_INFO, user);
      state.user = user;
    },

    [walletMeAction.fulfilled]: (state, action) => {
      const wallet = action?.payload?.data?.data?.wallet;
      webStorage.set(USER_WALLET, wallet);
      state.wallet = wallet;
    },
  },
});

export const { handleUpdateRememberLogin, handleUpdateIsAuth, handleSignOut } =
  authSlice.actions;

const { reducer: authReducer } = authSlice;
export default authReducer;
