import React from "react";
import { Col, Row } from "antd";
import useTranslation from "next-translate/useTranslation";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { handleUpdateShowLogin } from "store/user";

import Button from "@components/core/commons/Button";

const ForgotSuccess = ({ onCancel, setStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("forgotPass");

  const handleLogin = () => {
    setStep(1);
    onCancel();
    dispatch(handleUpdateShowLogin(true));
  };

  return (
    <div className="step-4">
      <div className="step-4__title">{t("title")}</div>

      <Row justify="center">
        <Col>
          <p className="step-4__label">{t("step4.label")}</p>
        </Col>
      </Row>

      <div className="step-4__layout">
        <div className="step-4__layout__button">
          <Button type="submit" onClick={handleLogin}>
            {t("step4.btn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

ForgotSuccess.propTypes = {
  setStep: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ForgotSuccess;
