import React from "react";

const TabCustom = ({
  title,
  active,
  keyActive,
  handleActive,
  hasTabBackground,
  width = false,
}) => {
  return (
    <div className="tab" style={{ width: `${width}` }}>
      <div
        className={`tab__item ${active === keyActive ? `active` : ""} ${
          hasTabBackground ? "tab-background" : ""
        }`}
        onClick={() => handleActive(keyActive)}
      >
        <div className="tab__item__background" />
        <p>{title}</p>
      </div>
    </div>
  );
};

export default TabCustom;
