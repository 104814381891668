import request from "@libs/base/request";

const updateUserApi = async (data) =>
  request({
    url: "/core/user/me",
    data,
    method: "PUT",
    tokenClient: true,
    msgSuccess: "successEditProfile",
  });

const changePassApi = async (data) =>
  request({
    url: "/core/auth/change-password",
    method: "POST",
    data,
    msgSuccess: "successChangePass",
    tokenClient: true,
  });

const requestOtpApi = async (data) =>
  request({
    url: "/core/auth/request-otp",
    method: "POST",
    data,
  });

const confirmOtpApi = async (data) =>
  request({
    url: "/core/auth/check-otp",
    method: "POST",
    data,
  });

const changePassForgotApi = async (data) =>
  request({
    url: "/core/auth/forgot-password",
    method: "POST",
    data,
    msgSuccess: "successChangePass",
  });

export {
  updateUserApi,
  changePassApi,
  requestOtpApi,
  confirmOtpApi,
  changePassForgotApi,
};
