const MAX_PLAYER = 5;

const EXPIRED_DAYS = 30;

const LEVELS = [
  {
    type: "USER",
    title: "User",
  },
  {
    type: "MEMBER",
    title: "Thành viên",
  },
  {
    type: "MEMBER_VIP",
    title: "Thành viên VIP",
  },
  {
    type: "TRUONG_HOC ",
    title: "Trường học",
  },
  {
    type: "CHUYEN_GIA_DAO_TAO",
    title: "Chuyên gia đào tạo",
  },
  {
    type: "FACILITATOR",
    title: "Người hướng dẫn",
  },
  {
    type: "CONG_TAC_VIEN",
    title: "Cộng tác viên",
  },
  {
    type: "HIEU_TRUONG",
    title: "Hiệu trưởng",
  },
  {
    type: "NHA_TO_CHUC",
    title: "Nhà tổ chức",
  },
  {
    type: "DAI_LY",
    title: "Đại lý",
  },
  {
    type: "CHI_NHANH",
    title: "Chi nhánh",
  },
  {
    type: "CHI_NHANH_TINH",
    title: "Chi nhánh tỉnh",
  },
  {
    type: "CHI_NHANH_VUNG",
    title: "Chi nhánh vùng",
  },
  {
    type: "BE_U",
    title: "BeU",
  },
];

const DATA_GENDER = [
  {
    key: 1,
    value: "MALE",
  },
  {
    key: 2,
    value: "FEMALE",
  },
  {
    key: 3,
    value: "RATHER_NOT_SAY",
  },
];

const COURSE_STATUS = [
  {
    key: 1,
    value: 1,
    label: "Đang hoạt động",
    color: "#EB222C",
  },
  {
    key: 2,
    value: 2,
    label: "Đang tham gia",
    color: "#EB222C",
  },
  {
    key: 3,
    value: 3,
    label: "Đã hoàn thành",
    color: "#25A519",
  },
  {
    key: 4,
    value: 4,
    label: "Không hoàn thành",
    color: "#9B9B9B",
  },
  {
    key: 5,
    value: 5,
    label: "Đã kết thúc",
    color: "#9B9B9B",
  },
  {
    key: 6,
    value: 6,
    label: "Chờ kết quả",
    color: "#1583E8",
  },
];

const TYPE = {
  TOP_UP: "TOP_UP",
  CASH_OUT: "CASH_OUT",
  BUY_PACKAGE_PLAY_COUNT: "BUY_PACKAGE_PLAY_COUNT",
  BUY_PACKAGE_PLAY_DAYS: "BUY_PACKAGE_PLAY_DAYS",
  BUY_MEMBER_CARD: "BUY_MEMBER_CARD",
  APPLY_CODE_MEMBER_CARD: "APPLY_CODE_MEMBER_CARD",
  APPLY_CODE_SCRATCH_CARD: "APPLY_CODE_SCRATCH_CARD",
  BONUS_FREE_TURN_PLAY: "BONUS_FREE_TURN_PLAY",
  EXCHANGE_ACCESSORY: "EXCHANGE_ACCESSORY",
  EXCHANGE_GIFT: "EXCHANGE_GIFT",
  REFUND_EXCHANGE_GIFT: "REFUND_EXCHANGE_GIFT",
  BUY_PRODUCT_KIT: "BUY_PRODUCT_KIT",
  BUY_MEMBER_CARD: "BUY_MEMBER_CARD",
  APPLY_CODE_MEMBER_CARD: "APPLY_CODE_MEMBER_CARD",
  BUY_COURSE: "BUY_COURSE",
  COMMISSION_BUY_PRODUCT_KIT: "COMMISSION_BUY_PRODUCT_KIT",
};

const STATUS = {
  SUCCESS: "SUCCESS",
  PROCESSING: "PROCESSING",
  CANCEL: "CANCEL",
};

export {
  LEVELS,
  TYPE,
  DATA_GENDER,
  MAX_PLAYER,
  EXPIRED_DAYS,
  COURSE_STATUS,
  STATUS,
};
