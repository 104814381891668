import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Head from "next/head";
import { useRouter } from "next/router";
import Image from "next/image";

import { handleUpdateIsAuth, getMeAction, walletMeAction } from "store/auth";

import Header from "../Header";
import Footer from "../Footer";

import webStorage from "@utils/webStorage";
import { formatImage } from "@utils/helpers/logic/image";
import {
  PAGE_TITLE,
  ASSETS_URL,
  PAGE_URL,
  PAGE_DESC,
  PAGE_SHARE_TITLE,
} from "configs/constant";

import iconGoToTop from "@public/icons/icon-go-to-top.svg";

export default function Layout({ children, data }) {
  const dispatch = useDispatch();
  const token = webStorage.getToken();
  const router = useRouter();
  const scrollRef = useRef();

  useEffect(() => {
    if (token) {
      dispatch(handleUpdateIsAuth(true));
    } else {
      dispatch(handleUpdateIsAuth(false));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (router.pathname && token) {
      dispatch(getMeAction());
      dispatch(walletMeAction());
    }
  }, [dispatch, token, router.pathname]);

  useEffect(() => {
    function reportWindowScroll() {
      if (scrollRef.current) {
        if (window.pageYOffset > 0) {
          scrollRef.current.style.display = "block";
        } else {
          scrollRef.current.style.display = "none";
        }
      }
    }
    reportWindowScroll();
    window.addEventListener("scroll", reportWindowScroll);
    return () => {
      window.removeEventListener("scroll", reportWindowScroll);
    };
  }, [scrollRef]);

  const onScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const imageUrl = data?.image
    ? `${ASSETS_URL}/${formatImage(data?.image)}`
    : "images/banner-slide.png";
  const url = `${PAGE_URL}${router.asPath}`;

  return (
    <>
      <Head>
        <title>{PAGE_TITLE}</title>
        <meta name="description" content={PAGE_DESC} />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content={data?.metaTitle || PAGE_SHARE_TITLE} />
        <meta
          itemProp="description"
          content={data?.metaDescription || PAGE_DESC}
        />
        <meta itemProp="image" content={imageUrl} />

        {/*Facebook Meta Tags  */}
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={data?.metaTitle || PAGE_SHARE_TITLE}
        />
        <meta
          property="og:description"
          content={data?.metaDescription || PAGE_DESC}
        />
        <meta property="og:image" content={imageUrl} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={data?.metaTitle || PAGE_SHARE_TITLE}
        />
        <meta
          name="twitter:description"
          content={data?.metaDescription || PAGE_DESC}
        />
        <meta name="twitter:image" content={imageUrl} />

        <link
          rel="preload"
          href="/fonts/NunitoSans/NunitoSans-Regular.ttf"
          as="font"
          crossOrigin=""
        />

        <link
          rel="preload"
          href="/fonts/BarlowCondensed/BarlowCondensed-ExtraBold.ttf"
          as="font"
          crossOrigin=""
        />
      </Head>

      <div className="layout">
        <Header />
        <div className="layout__main">{children}</div>
        {router?.pathname !== "/account-management" && <Footer />}
        <div
          className="layout__gototop"
          onClick={onScrollToTop}
          ref={scrollRef}
        >
          <Image src={iconGoToTop} alt="icon" />
        </div>
      </div>
    </>
  );
}
