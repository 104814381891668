import React, { useState } from "react";
import { Col, Form, Row, Statistic } from "antd";
import useTranslation from "next-translate/useTranslation";
import OtpInput from "react18-input-otp";
import PropTypes from "prop-types";

import { confirmOtpApi, requestOtpApi } from "@libs/apis/user";

import Button from "@components/core/commons/Button";

const ForgotOtpForm = ({ setStep, email, setOptConfrim }) => {
  const { t } = useTranslation("forgotPass");
  const [form] = Form.useForm();
  const { Countdown } = Statistic;

  const [otpTimer, setOtpTimer] = useState(true);
  const [timer, setTimer] = useState(Date.now() + 241 * 1000);
  const [otp, setOtp] = useState("");

  const onFinishForm = async (data) => {
    const res = await confirmOtpApi({
      otpCode: data?.otpCode,
      email,
    });
    if (res?.status === 201) {
      setStep(3);
      setOptConfrim(otp);
    }
  };

  const otpTime = (time) => {
    if (time < 100) {
      setOtp(null);
      setOtpTimer(false);
    }
  };

  const onChange = (otpChange) => {
    if (otpChange && otpTimer) {
      setOtp(otpChange);
    }
  };

  const reSendOtp = async () => {
    const res = await requestOtpApi({ email: email });
    if (res?.status === 201) {
      form.resetFields();
      setTimer(Date.now() + 241 * 1000);
      setOtp("");
    }
  };

  return (
    <div className="step-2">
      <Form
        name="forgotPassStep2"
        autoComplete="off"
        onFinish={onFinishForm}
        form={form}
      >
        <div className="step-2__title">{t("title")}</div>

        <Row justify="center">
          <Col>
            <p className="step-2__label">{t("step2.label")}</p>
          </Col>
        </Row>

        <div className="step-2__layout">
          <Form.Item name="otpCode" className="step-2__otp">
            <OtpInput
              onChange={onChange}
              numInputs={4}
              shouldAutoFocus={true}
              inputStyle={{
                width: "44px",
                height: "44px",
                margin: "0 0.25rem",
                fontSize: "18",
                fontWeight: "700",
                borderRadius: 12,
                backgroundColor: "#EE9416",
                border: "1px solid #D27B00",
              }}
            />
          </Form.Item>
          <Row justify="center">
            <Col>
              <p className="step-2__label">{t("step2.noteTime")}</p>
              <Countdown onChange={otpTime} value={timer} format="mm:ss" />
            </Col>
          </Row>

          <div className="step-2__layout__button">
            <Form.Item>
              <Button type="submit" disabled={otp?.length < 4}>
                {t("step2.btn")}
              </Button>
            </Form.Item>
          </div>
          <a className="step-2__resend" onClick={reSendOtp}>
            {t("step2.reSend")}
          </a>
        </div>
      </Form>
    </div>
  );
};

ForgotOtpForm.propTypes = {
  setStep: PropTypes.func,
  email: PropTypes.string,
  setOptConfrim: PropTypes.func,
};
export default ForgotOtpForm;
