import { useState } from "react";
import PropTypes from "prop-types";

import Image from "next/image";

import eye from "../../../../public/icons/eye.svg";
import eyeActive from "../../../../public/icons/eye-active.svg";

Input.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["text", "password"]),
  icon: PropTypes.any,
  isPassword: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  placeholder: "Input ...",
  type: "text",
  icon: "",
  isPassword: false,
  required: true,
  disabled: false,
};

export default function Input({
  title,
  placeholder,
  type,
  icon,
  isPassword,
  required,
  disabled,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  const onHandleClickEye = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="input">
      {title && (
        <div className="input__title">
          {title}
          {required && "*"}
        </div>
      )}

      <div className="input__layout">
        <div className="input__layout--icon">
          <Image src={icon} alt="icon" />
        </div>
        {!isPassword ? (
          <input
            disabled={disabled}
            type={type || "text"}
            placeholder={placeholder}
            {...rest}
          />
        ) : (
          <div className="input__layout__password">
            <div className="input__layout__password--icon--left">
              <Image src={icon} alt="icon" />
            </div>
            <input
              disabled={disabled}
              type={showPassword ? "text" : "password"}
              placeholder={placeholder}
              {...rest}
            />
            <div className="input__layout__password--icon--right">
              <Image
                src={showPassword ? eyeActive : eye}
                alt="eye"
                onClick={onHandleClickEye}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

Input.defaultProps = defaultProps;
