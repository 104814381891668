
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { Provider } from "react-redux";
import store from "store";
import { ToastContainer } from "react-toastify";
// import AuthProvider from "hooks/use-auth";
import NextNProgress from "nextjs-progressbar";

import Layout from "@components/core/layouts/Layout";

import "antd/dist/antd.css";
import "styles/globals.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "node_modules/react-modal-video/scss/modal-video.scss";

import "../styles/index.scss";

function MyApp({ Component, pageProps }) {
  return (
    <Provider store={store}>
      <Layout data={pageProps.detailsNews}>
        <ToastContainer
          position="top-center"
          hideProgressBar
          autoClose={3000}
        />
        <NextNProgress color="#FFC72C" />
        <Component {...pageProps} />
      </Layout>
    </Provider>
  );
}

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  