import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Dropdown, Menu, Collapse, Table, Button } from "antd";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import { handleUpdateShowLogin, handleUpdateShowRegister } from "store/user";
import {
  handleSignOut,
  loginAction,
  getMeAction,
  walletMeAction,
} from "store/auth";

import LoginForm from "@components/core/auth/LoginForm";
import RegisterForm from "@components/core/auth/RegisterForm";
import ForgotPassWord from "@components/core/auth/forgotPasswordStep";
import Modal from "@components/core/commons/Modal";
import TabCustom from "@components/core/commons/TabCustom";

import { menus, dropdown, menuProducts } from "./data";
import { ASSETS_URL } from "configs/constant";
import { handleOnClickRefLink } from "@utils/helpers/logic/copyLink";
import { renderLevel, nFormatter } from "@utils/helpers/common";
import { formatImage } from "@utils/helpers/logic/image";

// import newNoti from "@public/images/new-notification.png";
import logo from "@public/images/logo.svg";
import coin from "@public/images/coin.png";
import point from "@public/images/point.png";
import vip from "@public/images/vip.png";
import arrow from "@public/images/arrow-item.png";
import login from "@public/icons/login.svg";
// import register from "@public/icons/register.svg";
import openMenu from "@public/icons/menu.svg";
import closeMenu from "@public/icons/close.svg";
import avatar from "@public/images/avatar.png";

export default function Header() {
  const { Panel } = Collapse;
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation("header");

  const user = useSelector((state) => state?.authReducer?.user);
  const wallet = useSelector((state) => state?.authReducer?.wallet);
  const isAuth = useSelector((state) => state?.authReducer?.isAuth);
  const showLogin = useSelector((state) => state?.userReducer?.showLogin);
  const showRegister = useSelector((state) => state?.userReducer?.showRegister);

  const [showMission, setShowMission] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeMenu, setActiveMenu] = useState("/");
  const [copiedRefLink, setCopiedRefLink] = useState(false);
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [activeTabMission, setActiveTabMission] = useState(1);
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    const queryLogin = router.query?.login;

    if (queryLogin && !isAuth) {
      dispatch(handleUpdateShowLogin(true));
    }
  }, [dispatch, isAuth, router.query?.login]);

  useEffect(() => {
    const queryRegister = router.query?.register;

    if (queryRegister && !isAuth) {
      dispatch(handleUpdateShowRegister(true));
    }
  }, [dispatch, isAuth, router.query?.register]);

  useEffect(() => {
    setActiveMenu(router.pathname);
  }, [router]);

  const handleOnClickLogin = () => {
    dispatch(handleUpdateShowLogin(true));
    dispatch(handleUpdateShowRegister(false));
  };

  const handleOnClickRegister = () => {
    dispatch(handleUpdateShowRegister(true));
    dispatch(handleUpdateShowLogin(false));
  };

  const handleOnCancelLogin = () => {
    dispatch(handleUpdateShowLogin(false));
  };

  const handleOnCancelRegister = () => {
    dispatch(handleUpdateShowRegister(false));
    if (router.query?.register || router.query?.ref) {
      router.replace("/", undefined, { shallow: true });
    }
  };

  const handleShowForgotPass = () => {
    dispatch(handleUpdateShowLogin(false));
    setShowForgotPass(true);
  };

  const handleOnClickOpenMenu = () => {
    setShowDrawer(!showDrawer);
  };

  const handleOnFinishLogin = (val) => {
    if (val) {
      handleOnCancelLogin();
    }
  };

  const handleOnFinishRegister = async (val) => {
    if (val) {
      const res = await dispatch(loginAction(val));
      if (res?.payload?.status === 201) {
        await dispatch(getMeAction());
        await dispatch(walletMeAction());
        handleOnCancelRegister();
        setShowWelcome(true);
      }
    }
  };

  const handleOnClickLogo = () => {
    router.push("/");
  };

  const handleOnClickSignOut = () => {
    dispatch(handleSignOut());
    router.push("/");
  };

  const menuProduct = (
    <Menu
      items={menuProducts?.map((item) => ({
        key: item?.id,
        label: (
          <div
            className="dropdown-item-wrapper"
            onClick={() => {
              router.push(item?.link || "");
            }}
          >
            <p>{t(item?.name)}</p>
          </div>
        ),
      }))}
    />
  );

  const menu = (
    <Menu
      items={dropdown?.map((item) => ({
        key: item?.id,
        label: (
          <div
            className="dropdown-item-wrapper"
            onClick={() => {
              if (item?.isSignOut) {
                handleOnClickSignOut();
              }
              if (item?.link) {
                router.push(item?.link || "");
              }
            }}
          >
            <p className={`${item?.isSignOut ? "logout" : ""}`}>
              {t(item?.name)}
            </p>
            {item?.hasArrow && (
              <Image src={arrow} alt="arrow" width={18} height={18} />
            )}
            {item?.hasLink && (
              <div
                className="dropdown-item-wrapper__link"
                onClick={() => {
                  handleOnClickRefLink(user?.phone || "");
                  setCopiedRefLink(true);
                }}
              >
                {copiedRefLink ? "copied" : "link"}
              </div>
            )}
          </div>
        ),
      }))}
    />
  );

  const missionTabs = [
    {
      key: 1,
      title: t("mission.daily"),
    },
    {
      key: 2,
      title: t("mission.monthly"),
    },
    {
      key: 3,
      title: t("mission.achievement"),
    },
  ];

  const checkActiveTab = (link) => {
    if (link === "/") {
      return activeMenu === link;
    } else {
      return activeMenu?.indexOf(link) !== -1;
    }
  };

  const columns = [
    {
      key: "code",
      align: "center",
      width: "7%",
      render: (_, record, index) => (
        <div className="table-index">{index + 1}.</div>
      ),
    },
    {
      key: "status",
      align: "left",
      width: "auto",
      render: () => <div className="table-title">đăng nhập hàng ngày</div>,
    },
    {
      key: "progress",
      align: "center",
      width: "7%",
      render: () => <div className="table-progress">0/1</div>,
    },
    {
      key: "reward",
      align: "center",
      width: "25%",
      render: () => (
        <div className="table-reward">
          <div className="table-reward__item">
            <Image src={point} alt="point" width={20} height={20} />
            +30
          </div>
          <div className="table-reward__item">
            <span>EXP</span>
            +50
          </div>
        </div>
      ),
    },
    {
      key: "action",
      align: "center",
      width: "25%",
      render: () => (
        <div className="table-button">
          <button>{t("mission.receiveReward")}</button>
        </div>
      ),
    },
  ];

  return (
    <div className="header">
      {showMission && (
        <Modal
          visible={showMission}
          onCancel={() => setShowMission(false)}
          width={856}
          className="mission-modal"
        >
          <div className="mission-modal-wrapper">
            <div className="mission-modal-wrapper__tabs">
              {missionTabs?.map((item, index) => (
                <React.Fragment key={index}>
                  <TabCustom
                    title={item?.title || ""}
                    keyActive={item?.key || ""}
                    active={activeTabMission || ""}
                    handleActive={setActiveTabMission}
                    hasTabBackground
                  />
                </React.Fragment>
              ))}
            </div>
            <Table
              columns={columns}
              pagination={false}
              dataSource={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
              scroll={{ y: "500px", x: "760px" }}
            />
          </div>
        </Modal>
      )}
      <div className="header__left">
        <div className="header__left__logo">
          <Image
            src={logo}
            alt="logo"
            width={100}
            height={60}
            onClick={handleOnClickLogo}
          />
        </div>
        <div className="header__left__menu">
          {menus.map((item) =>
            item?.isDropDown ? (
              <Dropdown
                arrow
                destroyPopupOnHide
                overlay={menuProduct}
                placement="bottom"
                key={item?.id}
              >
                <div
                  className={`header__left__menu--item ${
                    checkActiveTab(item?.link) ? "active-menu" : ""
                  }`}
                >
                  {t(item?.name)}
                  <ArrowProduct className="header__left__menu--item--arrow" />
                </div>
              </Dropdown>
            ) : (
              <div
                key={item?.id}
                className={`header__left__menu--item ${
                  checkActiveTab(item?.link) ? "active-menu" : ""
                }`}
              >
                {item?.newTab ? (
                  <Link href={item?.link} passHref>
                    <a target="_blank" rel="noreferer">
                      {t(item?.name)}
                    </a>
                  </Link>
                ) : (
                  <Link href={item?.link}>{t(item?.name)}</Link>
                )}
              </div>
            ),
          )}
        </div>
      </div>
      <div className="header__right">
        <div className="desktop">
          {isAuth && user ? (
            <Dropdown
              arrow
              destroyPopupOnHide
              overlay={menu}
              placement="bottomLeft"
              onOpenChange={(open) => {
                if (!open) {
                  setCopiedRefLink(false);
                }
              }}
            >
              {/* <div
                className="header__right__noti"
                onClick={() => setShowMission(true)}
              >
                <Image src={newNoti} alt="noti" width="100%" height="100%" />
              </div> */}
              <div className="header__right__user">
                <div
                  className="header__right__user--avatar"
                  style={{
                    backgroundImage: `url(${ASSETS_URL}/${
                      formatImage(user?.frame) || "/images/icon-profile.svg"
                    })`,
                  }}
                >
                  <>
                    <Image
                      src={
                        user?.avatar?.length > 0
                          ? `${ASSETS_URL}/${formatImage(user?.avatar)}`
                          : avatar
                      }
                      alt="avatar"
                      width={100}
                      height={100}
                    />
                    <div className="level">
                      <Image
                        src={renderLevel()}
                        alt="level"
                        width={62}
                        height={30}
                      />
                    </div>
                  </>
                </div>

                <div className="header__right__user--info">
                  <div className="header__right__user--info--name">
                    {user?.fullName || ""}
                    {user?.isVip && (
                      <Image
                        src={vip}
                        alt="vip"
                        width={37}
                        height={15}
                        layout="fixed"
                      />
                    )}
                  </div>
                  <div className="header__right__user--info--type">
                    <div className="header__right__user--info--type--item">
                      <Image src={coin} alt="coin" width={16} height={16} />
                      <p>{nFormatter(wallet?.coin) || 0}</p>
                    </div>
                    <div className="separator" />
                    <div className="header__right__user--info--type--item">
                      <Image src={point} alt="point" width={16} height={16} />
                      <p>{nFormatter(wallet?.point) || 0}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown>
          ) : (
            <div className="header__right__button">
              {/* <div
                className="header__right__button--item"
                onClick={handleOnClickRegister}
              >
                <Image
                  src={register}
                  alt="register"
                  width={20}
                  height={20}
                  layout="fixed"
                />
                {t("signUp")}
              </div> */}
              <div
                className="header__right__button--item header__right__button--item--login"
                onClick={handleOnClickLogin}
              >
                <Image
                  src={login}
                  alt="login"
                  width={20}
                  height={20}
                  layout="fixed"
                />
                {t("login")}
              </div>
            </div>
          )}
        </div>
        <div className="mobile">
          {!isAuth && (
            <div className="mobile__btn" onClick={handleOnClickLogin}>
              {t("login")}
            </div>
          )}
          <Image
            src={showDrawer ? closeMenu : openMenu}
            alt="menu"
            width={32}
            height={32}
            onClick={handleOnClickOpenMenu}
          />
        </div>
      </div>

      <Drawer
        placement="right"
        open={showDrawer}
        width="100vw"
        onClose={() => {
          setShowDrawer(false);
          setCopiedRefLink(false);
        }}
      >
        <div className="header__drawer">
          {isAuth && user && (
            <div className="header__drawer--wrapper">
              <div className="header__drawer--wrapper__user">
                <div
                  className="header__drawer--wrapper__user--avatar"
                  style={{
                    backgroundImage: `url(${ASSETS_URL}/${
                      formatImage(user?.frame) || "/images/icon-profile.svg"
                    })`,
                  }}
                >
                  <>
                    <Image
                      src={
                        user?.avatar?.length > 0
                          ? `${ASSETS_URL}/${formatImage(user?.avatar)}`
                          : avatar
                      }
                      alt="avatar"
                      width={100}
                      height={100}
                    />
                    <div className="level">
                      <Image
                        src={renderLevel()}
                        alt="level"
                        width={62}
                        height={30}
                      />
                    </div>
                  </>
                </div>
                <div className="header__drawer--wrapper__user--info">
                  <div className="header__drawer--wrapper__user--info--name">
                    {user?.fullName}
                    {user?.isVip && (
                      <Image
                        src={vip}
                        alt="vip"
                        width={37}
                        height={15}
                        layout="fixed"
                      />
                    )}
                  </div>
                  <div className="header__drawer--wrapper__user--info--type">
                    <div className="header__drawer--wrapper__user--info--type--item">
                      <Image src={coin} alt="coin" width={16} height={16} />
                      <p>{nFormatter(wallet?.coin) || 0}</p>
                    </div>
                    <div className="separator" />
                    <div className="header__drawer--wrapper__user--info--type--item">
                      <Image src={point} alt="point" width={16} height={16} />
                      <p>{nFormatter(wallet?.point) || 0}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header__drawer--wrapper__options">
                {dropdown?.map((item) => (
                  <div
                    key={item?.id}
                    className="header__drawer--wrapper__options--item"
                    onClick={() => {
                      if (item?.isSignOut) {
                        handleOnClickSignOut();
                      }
                      if (item?.link) {
                        router.push(item?.link || "");
                        setShowDrawer(false);
                      }
                    }}
                  >
                    <p className={`${item?.isSignOut ? "logout" : ""}`}>
                      {t(item?.name)}
                    </p>
                    {item?.hasArrow && (
                      <Image src={arrow} alt="arrow" width={18} height={18} />
                    )}
                    {item?.hasLink && (
                      <div
                        className="header__drawer--wrapper__options--item__link"
                        onClick={() => {
                          handleOnClickRefLink(user?.phone || "");
                          setCopiedRefLink(true);
                        }}
                      >
                        {copiedRefLink ? "copied" : "link"}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="header__drawer--menu">
            {menus.map((item) =>
              item?.isDropDown ? (
                <Collapse ghost expandIconPosition="end" key={item?.id}>
                  <Panel
                    header={t(item?.name)}
                    key={item?.id}
                    showArrow={false}
                  >
                    {menuProducts.map((el) => (
                      <a
                        key={el?.id}
                        href={el?.link}
                        className={
                          checkActiveTab(el?.link) ? "active-route" : ""
                        }
                      >
                        {t(el?.name)}
                      </a>
                    ))}
                  </Panel>
                </Collapse>
              ) : (
                <a
                  key={item?.id}
                  href={item?.link}
                  target={item?.newTab ? "_blank" : ""}
                  className={`${
                    checkActiveTab(item?.link) ? "active-route" : ""
                  }`}
                >
                  {t(item?.name)}
                </a>
              ),
            )}
          </div>
        </div>
      </Drawer>

      {showLogin && (
        <LoginForm
          visible={showLogin}
          onCancel={handleOnCancelLogin}
          onClickRegister={handleOnClickRegister}
          onFinishLogin={(val) => handleOnFinishLogin(val)}
          handleShowForgotPass={handleShowForgotPass}
        />
      )}

      {showRegister && (
        <RegisterForm
          visible={showRegister}
          onCancel={handleOnCancelRegister}
          onClickLogin={handleOnClickLogin}
          onFinishRegister={(val) => handleOnFinishRegister(val)}
        />
      )}

      {showForgotPass && (
        <ForgotPassWord
          visible={showForgotPass}
          onCancel={() => {
            setShowForgotPass(!showForgotPass);
          }}
        />
      )}

      {showWelcome && (
        <Modal
          visible={showWelcome}
          onCancel={() => setShowWelcome(false)}
          closable
        >
          <div className="welcome-modal">
            Xin chào <strong>{user?.fullName}</strong> đến với CLB Be Rich!
            <br />
            Thay lời cảm ơn, chúng tôi gửi tặng bạn 10 lượt trải nghiệm miễn phí
            cho LẦN ĐẦU ĐĂNG KÝ.
            <br />
            <strong>Lưu ý:</strong> Bạn sẽ được tặng thêm 2 lượt trải
            nghiệm/ngày và số lượt trải nghiệm này sẽ mất vào cuối ngày.
            <br />
            Hiện tại, phiên bản trải nghiệm này chúng tôi chưa hỗ trợ trình
            duyệt Safari. <br /> Bạn vui lòng chuyển sang loại trình duyệt khác
            trong danh sách khuyến cáo
          </div>
          <div className="welcome-modal__wrap-btn">
            <Link href="/">
              <Button
                onClick={() => setShowWelcome(false)}
                className="card__success__btn"
              >
                {t("homepage")}
              </Button>
            </Link>
            <Link href="/experience">
              <Button
                onClick={() => setShowWelcome(false)}
                className="card__success__btn"
              >
                {t("experience")}
              </Button>
            </Link>
          </div>
        </Modal>
      )}
    </div>
  );
}

const ArrowProduct = ({ className }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.5 4.25L6 7.75L2.5 4.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
