import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

import { signUpAction } from "store/auth";
import { getListSchoolAction } from "store/user";

import { rexgexEmail, regexPhone } from "@utils/regex";

import Modal from "@components/core/commons/Modal";
import Input from "@components/core/commons/Input";
import Button from "@components/core/commons/Button";
import Select from "@components/core/commons/Select";

import person from "@public/icons/person.svg";
import password from "@public/icons/password.svg";
import username from "@public/icons/username.svg";

RegisterForm.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickLogin: PropTypes.func,
};

const defaultProps = {
  visible: false,
  onCancel: () => {},
  onClickLogin: () => {},
};

export default function RegisterForm({
  visible,
  onCancel,
  onClickLogin,
  onFinishRegister,
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation("register");
  const [form] = Form.useForm();

  const listSchool = useSelector((state) => state?.userReducer?.listSchool);

  const [loading, setLoading] = useState(false);
  const [refPhone, setRefPhone] = useState("");

  useEffect(() => {
    dispatch(getListSchoolAction());
  }, [dispatch]);

  useEffect(() => {
    const query = router.query;
    if (query?.ref) {
      form.setFieldValue("refPhone", query?.ref);
    }
    setRefPhone(query?.ref || "");
  }, [router, form]);

  const onFinishForm = async (values) => {
    setLoading(true);
    const data = {
      phone: values?.username,
      username: values?.username,
      fullName: values?.fullname,
      password: values?.password,
      email: values?.email,
    };

    if (values?.school)
      data.school = listSchool?.find(
        (item) => item?.name === values?.school,
      )?.id;
    if (values?.refPhone) data.referralBy = values?.refPhone;

    const res = await dispatch(signUpAction(data));
    if (res?.payload?.status === 201) {
      form.resetFields();
      onFinishRegister({ phone: data.phone, password: data.password });
    }
    setLoading(false);
  };

  return (
    <Modal visible={visible} onCancel={onCancel} closable maskClosable={false}>
      <div className="register">
        <Form
          name="loginForm"
          autoComplete="off"
          onFinish={onFinishForm}
          initialValues={{ remember: true, ref: refPhone }}
          form={form}
        >
          <div className="register__title">{t("title")}</div>
          <div className="register__layout">
            <Form.Item
              rules={[
                { required: true, message: t("ruleUsername") },
                {
                  pattern: new RegExp(regexPhone),
                  message: t("ruleUsernameType"),
                },
                {
                  min: 10,
                  message: t("ruleUsernameLength"),
                },
                {
                  max: 14,
                  message: t("ruleUsernameLength"),
                },
              ]}
              name="username"
            >
              <Input
                title={t("username")}
                type="text"
                icon={username}
                placeholder={t("username")}
              />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: t("ruleEmail") },
                {
                  pattern: new RegExp(rexgexEmail),
                  message: t("wrongEmail"),
                },
              ]}
              name="email"
            >
              <Input
                title={t("email")}
                type="text"
                icon={username}
                placeholder={t("email")}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: t("ruleName") }]}
              name="fullname"
            >
              <Input
                title={t("fullname")}
                type="text"
                icon={person}
                placeholder={t("fullname")}
              />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: t("rulePassword") },
                { min: 6, message: t("ruleMinPassword") },
              ]}
              name="password"
            >
              <Input
                title={t("password")}
                type="password"
                isPassword
                icon={password}
                placeholder={t("password")}
              />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: t("ruleRePassword") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("ruleMatchPassword")));
                  },
                }),
              ]}
              name="rePassword"
            >
              <Input
                title={t("confirmPassword")}
                type="password"
                isPassword
                icon={password}
                placeholder={t("confirmPassword")}
              />
            </Form.Item>
            <Form.Item rules={[{ message: t("ruleSchool") }]} name="school">
              <Select
                options={listSchool}
                icon={person}
                title={t("school")}
                placeholder={t("schoolPlaceHolder")}
              />
            </Form.Item>
            <Form.Item name="refPhone">
              <Input
                title={t("refPlaceholder")}
                type="text"
                icon={person}
                placeholder={t("refPlaceholder")}
                required={false}
                disabled={refPhone !== ""}
              />
            </Form.Item>
            <div className="register__layout__button">
              <Form.Item>
                <Button type="submit" htmlType="submit" disabled={loading}>
                  {t("title")}
                </Button>
              </Form.Item>
            </div>
            <div className="login__layout__info">
              {t("hadAccount")}{" "}
              <span onClick={onClickLogin}>{t("loginNow")}</span>{" "}
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

RegisterForm.defaultProps = defaultProps;
