import useTranslation from "next-translate/useTranslation";

export default function ToastMessage({
  message,
  description = "",
  translation = "",
}) {
  const { t } = useTranslation(translation);
  return (
    <div>
      {t(message)}
      {description}
    </div>
  );
}
