import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Form } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

import {
  loginAction,
  getMeAction,
  walletMeAction,
  handleUpdateRememberLogin,
} from "store/auth";

import Modal from "@components/core/commons/Modal";
import Input from "@components/core/commons/Input";
import Button from "@components/core/commons/Button";

import username from "public/icons/username.svg";
import password from "public/icons/password.svg";

LoginForm.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickRegister: PropTypes.func,
  onFinishLogin: PropTypes.func,
  handleShowForgotPass: PropTypes.func,
};

const defaultProps = {
  visible: false,
  onCancel: () => {},
  onClickRegister: () => {},
  onFinishLogin: () => {},
  handleShowForgotPass: () => {},
};

export default function LoginForm({
  visible,
  onCancel,
  onClickRegister,
  onFinishLogin,
  handleShowForgotPass,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation("login");

  const rememberLogin = useSelector(
    (state) => state?.authReducer?.rememberLogin,
  );

  const onHandleClickRememberLogin = useCallback(() => {
    dispatch(handleUpdateRememberLogin(!rememberLogin));
  }, [dispatch, rememberLogin]);

  const [loading, setLoading] = useState(false);

  const onFinishForm = async (values) => {
    setLoading(true);

    const data = {
      password: values?.password,
    };

    if (values?.username?.includes("@")) {
      data.email = values?.username?.trim();
    } else {
      data.phone = values?.username?.trim();
    }

    const res = await dispatch(loginAction(data));
    if (res?.payload?.status === 201) {
      await dispatch(getMeAction());
      await dispatch(walletMeAction());
      onFinishLogin(true);
    }
    setLoading(false);
  };

  return (
    <Modal visible={visible} onCancel={onCancel} closable>
      <div className="login">
        <Form
          name="loginForm"
          autoComplete="off"
          onFinish={onFinishForm}
          initialValues={{ remember: true }}
        >
          <div className="login__title">{t("title")}</div>
          <div className="login__layout">
            <Form.Item
              rules={[{ required: true, message: t("ruleUsername") }]}
              name="username"
            >
              <Input
                title={t("username")}
                type="text"
                icon={username}
                placeholder={t("usernamePlaceholder")}
              />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: t("rulePassword") },
                { min: 6, message: t("ruleMinPassword") },
              ]}
              name="password"
            >
              <Input
                title={t("password")}
                type="password"
                isPassword
                icon={password}
                placeholder={t("password")}
              />
            </Form.Item>
            <div className="login__layout__options">
              <div className="login__layout__options--rememberLogin">
                <Checkbox
                  checked={rememberLogin}
                  onChange={onHandleClickRememberLogin}
                />
                <p>{t("rememberLogin")}</p>
              </div>
              <div
                className="login__layout__options--forgetPassword"
                onClick={handleShowForgotPass}
              >
                {t("forgetPassword")}
              </div>
            </div>
            <div className="login__layout__button">
              <Form.Item>
                <Button type="submit" htmlType="submit" disabled={loading}>
                  {t("title")}
                </Button>
              </Form.Item>
            </div>
            <div className="login__layout__info">
              {t("dontHaveAccount")}{" "}
              <span onClick={onClickRegister}>{t("registerNow")}</span>{" "}
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

LoginForm.defaultProps = defaultProps;
