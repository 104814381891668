import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import userReducer from "./user";

const store = configureStore({
  reducer: {
    authReducer,
    userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
