import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "@components/core/commons/Modal";
import ForgotEmailForm from "./ForgotInpuEmail";
import ForgotOtpForm from "./ForgotConfirmOtp";
import ForgotChangePass from "./ForgotChangePass";
import ForgotSuccess from "./ForgotSuccess";

const ForgotPassWord = ({ visible, onCancel }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otpConfirm, setOptConfirm] = useState("");

  return (
    <Modal visible={visible} onCancel={onCancel}>
      {step === 1 && <ForgotEmailForm setEmail={setEmail} setStep={setStep} />}
      {step === 2 && (
        <ForgotOtpForm
          setOptConfrim={setOptConfirm}
          email={email}
          setStep={setStep}
        />
      )}
      {step === 3 && (
        <ForgotChangePass
          email={email}
          otpConfirm={otpConfirm}
          setStep={setStep}
        />
      )}
      {step === 4 && <ForgotSuccess onCancel={onCancel} setStep={setStep} />}
    </Modal>
  );
};

ForgotPassWord.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickLogin: PropTypes.func,
};

export default ForgotPassWord;
