import React from "react";
import { Col, Form, Row } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

import { requestOtpApi } from "@libs/apis/user";

import { rexgexEmail } from "@utils/regex";

import Input from "@components/core/commons/Input";
import Button from "@components/core/commons/Button";

import iconEmail from "@public/icons/icon-email.svg";

const ForgotEmailForm = ({ setEmail, setStep }) => {
  const { t } = useTranslation("forgotPass");
  const [form] = Form.useForm();

  const onFinishForm = async (data) => {
    const res = await requestOtpApi(data);
    if (res?.status === 201) {
      setEmail(data?.forgetEmail);
      setStep(2);
    }
  };

  return (
    <div className="step-1">
      <Form
        name="forgotPassStep1"
        autoComplete="off"
        onFinish={onFinishForm}
        form={form}
      >
        <div className="step-1__title">{t("title")}</div>

        <Row justify="center">
          <Col>
            <p className="step-1__label">{t("step1.labelEmail")}</p>
          </Col>
        </Row>
        <div className="step-1__layout">
          <Form.Item
            rules={[
              { required: true, message: t("step1.requiredEmail") },
              {
                pattern: new RegExp(rexgexEmail),
                message: t("step1.ruleEmail"),
              },
            ]}
            name="forgetEmail"
          >
            <Input
              type="text"
              icon={iconEmail}
              placeholder={t("step1.plachoderEmail")}
            />
          </Form.Item>

          <div className="step-1__layout__button">
            <Form.Item>
              <Button type="submit">{t("step1.btn")}</Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

ForgotEmailForm.propTypes = {
  setStep: PropTypes.func,
  setEmail: PropTypes.func,
};
export default ForgotEmailForm;
