export const API_SERVER = process.env.NEXT_PUBLIC_APP_API;
export const API_SERVER_SSR = process.env.NEXT_PUBLIC_APP_API_SSR;
export const TOKEN_KEY = process.env.NEXT_PUBLIC_TOKEN_KEY || "access-token";
export const IMG_URI = process.env.NEXT_PUBLIC_IMG_URI;
export const APP_URI = process.env.NEXT_PUBLIC_APP_URI;
export const PAGE_SIZE_OPTIONS = [
  {
    key: 1,
    label: "12 / trang",
    value: 12,
  },
  {
    key: 2,
    label: "24 / trang",
    value: 24,
  },
  {
    key: 3,
    label: "48 / trang",
    value: 48,
  },
  {
    key: 4,
    label: "100 / trang",
    value: 100,
  },
];
export const PAGE_LIMIT = 20;
export const MAX_AGE = 900; //Seconds
export const SWR = 1800; //Seconds
export const LIMIT_BANNER = 6;
export const LIMIT_FAVORITE_PRODUCT = 12;
export const LINK_FB = process.env.NEXT_PUBLIC_LINK_FB;
export const LINK_ZALO = process.env.NEXT_PUBLIC_LINK_ZALO;
export const NUMBER_PHONE = process.env.NEXT_PUBLIC_NUMBER_PHONE;
export const USER_INFO = "user-info";
export const USER_WALLET = "user-wallet";
export const ASSETS_URL = process.env.NEXT_PUBLIC_APP_ASSET_URL;
export const PAGE_TITLE = "Clb Be Rich - Nơi Đào Tạo ...";
export const PAGE_SHARE_TITLE = "Cộng đồng đào tạo tư duy";
export const PAGE_DESC =
  "Clb Be Rich nơi khai mở tư duy tài chính cho người Việt trẻ. Muốn Việt Nam giàu có, nếu KHÔNG bắt đầu từ Mình, thì ai là người bắt đầu. ";
export const PAGE_URL = process.env.NEXT_PUBLIC_DOMAIN;
export const API_BEUNICORN = process.env.NEXT_PUBLIC_BEUNICORN_API;
export const POPUP_STATUS = "popup-status";
export const BENEFIT_URL =
  "https://www.facebook.com/clbberich/posts/pfbid034w7JGtifaZDwM4Rvt34jGKUxt4aqDy79AiS6oPN8UhQTBBNxVYZgKwJzv8ym1bFrl";
export const CONVERT_RATE = 1000;
export const PAGE_SIZE_ACCESSORY = 8;
export const PAGE_LIMIT_ACCESSORY = 8;
export const FANPAGE_URL = "https://www.facebook.com/clbberich";
export const RESOURCE_URL = "https://berichbox.com";
export const VIDEO_URL =
  "https://www.youtube.com/channel/UCDXloXu5hvCZ1HHTASq0JYA";
export const TRAINING_URL = "https://daotao.clbberich.com";
export const SHARING_URL = "https://chiasehanhphuc.com";
export const LINK_POLICY = "/news/9-quyen-loi-danh-cho-thanh-vien-clb-be-rich";
export const LINK_DIAMOND = "https://beandiamond.com/";
export const LINK_BERICH = "https://coberich.com/";
export const LINK_UNICORN = "https://www.cokylan.com/";
export const LINK_FAQ = "/help";
export const LINK_DAOTAO =
  "https://daotao.clbberich.com/user/course-management/detail";
