import PropTypes from "prop-types";

Button.propTypes = {
    type: PropTypes.oneOf(["button", "submit"]),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

const defaultProps = {
    type: "button",
    onClick: () => {},
    disabled: false,
};

export default function Button({ children, type, onClick, disabled }) {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className="button"
        >
            {children}
        </button>
    )
}

Button.defaultProps = defaultProps;