import { Select as SelectBase } from "antd";
import PropTypes from "prop-types";

import Image from "next/image";

import eye from "../../../../public/icons/eye.svg";

Select.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  icon: PropTypes.object,
  placeholder: PropTypes.string,
};

const defaultProps = {
  title: "Select",
  options: [
    { label: "AA", value: "AA" },
    { label: "BB", value: "BB" },
  ],
  icon: eye,
  placeholder: "Select",
};

export default function Select({ title, options, icon, placeholder, ...rest }) {
  return (
    <div className="select">
      <div className="select__title">{title}</div>
      <div className="select__icon">
        <Image src={icon} alt="icon" />
      </div>
      <SelectBase
        allowClear
        showSearch
        showArrow={false}
        placeholder={placeholder}
        {...rest}
      >
        {options.map((item, index) => (
          <SelectBase.Option
            key={item?.id || index}
            value={item?.name}
            className="select__options"
          >
            {item?.name}
          </SelectBase.Option>
        ))}
      </SelectBase>
    </div>
  );
}

Select.defaultProps = defaultProps;
