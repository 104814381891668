import moment from "moment";

import { LEVELS } from "configs/enums";

import iconLevel1 from "@public/images/icon-level-1.svg";
import iconLevel2 from "@public/images/icon-level-2.svg";
import iconLevel3 from "@public/images/icon-level-3.svg";
import iconLevel4 from "@public/images/icon-level-4.svg";
import iconLevel5 from "@public/images/icon-level-5.svg";
import iconLevel6 from "@public/images/icon-level-6.svg";
import iconLevel7 from "@public/images/icon-level-7.svg";
import iconLevel8 from "@public/images/icon-level-8.svg";
import iconLevel9 from "@public/images/icon-level-9.svg";
import iconLevel10 from "@public/images/icon-level-10.svg";

export const openInNewTab = (url, item, token, isNewbieCoBeRich = false) => {
  if (item) {
    let urlOpen = `${url}/verify/?play=true&roomCode=${item?.code}&roomId=${item?.id}&token=${token}`;
    if (isNewbieCoBeRich) urlOpen += `&isNewbieCoBeRich=true`;
    window.open(urlOpen, `${item?.id}`);
  } else {
    window.open(url, "_blank");
  }
};

export const formatMoney = (money, currency = true) => {
  return `${money?.toLocaleString("it-IT")}${currency ? " VNĐ" : ""}`;
};

export const formatNumber = (number) => {
  const temp = Number(number);
  if (temp === 0) return `0`;
  return Number(number) < 10 ? `0${Number(number)}` : `${Number(number)}`;
};

export const formatDate = (date) => {
  const dateArr = date?.split("-");

  const year = dateArr?.[0];
  const month = dateArr?.[1];
  const day = dateArr?.[2];

  return `${day}/${month}/${year}`;
};

export const checkBankerRoomReady = (date, time) => {
  const timeArr = time?.split(":");

  const end = `${date} ${Number(timeArr?.[0]) + 2}:${timeArr?.[1]}`;

  const today = moment(new Date()).format("MMMM Do YYYY, h:mm:ss a");
  const startTime = moment(`${date} ${time}`).format("MMMM Do YYYY, h:mm:ss a");
  const endTime = moment(end).format("MMMM Do YYYY, h:mm:ss a");

  return moment(today).isBetween(startTime, endTime);
};

export const renderLevel = (level) => {
  switch (level) {
    case 2:
      return iconLevel2;
    case 3:
      return iconLevel3;
    case 4:
      return iconLevel4;
    case 5:
      return iconLevel5;
    case 6:
      return iconLevel6;
    case 7:
      return iconLevel7;
    case 8:
      return iconLevel8;
    case 9:
      return iconLevel9;
    case 10:
      return iconLevel10;
    default:
      return iconLevel1;
  }
};

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Qua" },
    { value: 1e18, symbol: "Qui" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const renderLevelTitle = (level) => {
  return LEVELS.find((item) => item?.type === level)?.title || "Người dùng";
};
