import request from "@libs/base/request";

const getNewsApi = async (params) =>
  request({
    url: "/v1/news",
    method: "GET",
    params,
  });

const getDetailsNewsApi = async (slug, params) =>
  request({
    url: `/v1/news/slug/${slug}`,
    method: "GET",
    params,
  });

const subscribingApi = async (data) =>
  request({
    url: "/v1/recipient",
    method: "POST",
    data,
    msgSuccess: "successSubscribing",
  });

export { getNewsApi, getDetailsNewsApi, subscribingApi };
